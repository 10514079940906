/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
// Languages
export const languages = {
	en: 'en',
	fi: 'fi'
}

export const routerPath = {
	dashboard: '/dashboard',
	adminDashboard: '/admin/dashboard',
	adminReport: '/admin/reports',
	adminCompanies: '/admin/companies',
	adminCompanyManagement: '/admin/company/:type',
	adminCompanyManagementDetail: '/admin/company/:type/:id',
	login: '/login',
	forgetPassword: '/forget-password',
	resetPassword: '/login/reset-password',
	//users
	userManagement: '/userManagement',
	userForms: '/userManagement/forms/:formType/:id',
	//
	serviceRequests: '/serviceRequests/:view',
	serviceRequestsEdit: '/serviceRequests/:view/:id',
	devices: '/devices',
	contactRequests: '/contactRequests/:view',
	orders: '/orders',
	training: '/training/:view'
}
//cyber security feeds
export const FEEDS_PERIOD = 7
export const FIRST_N_FEEDS = 7
export const CYBER_SECURITY_WEB = 'https://www.kyberturvallisuuskeskus.fi'
export const CYBER_SECURITY_RSS =
	'https://www.kyberturvallisuuskeskus.fi/feed/rss/fi'
export const CURRENT_AFFAIRS_RSS = 'https://blog.vmit.fi/blog/rss.xml'
export const APPLIXURE_URL = 'https://app.applixure.com'
export const KAUPPA_URL = 'https://kauppa.vmit.fi/'
export const INPUT_SEARCH_TIME = 1000
export const WARRANTY_VALUE = {
	supported: 'supported',
	expiring: 'expiring',
	expired: 'expired',
	undefined: 'undefined'
}
export const OS_VALUE = {
	supported: 'supported',
	expiring: 'expiring',
	expired: 'expired',
	unknown: 'unknown'
}
export const COMPANY_ACTIONS = {
	add_aula: 0,
	remove_aula: 1,
	add_ecommerce: 2,
	remove_ecommerce: 3
}
export const OS_OPTIONS = [
	{
		label: 'PAGES.DEVICES.STATUSES.SUPPORTED',
		value: OS_VALUE.supported,
		labelColor: {
			classColor: 'os-supported',
			basic: 'green',
			gradient: {
				start: '#637BFD',
				end: '#0127FB'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.EXPIRING',
		value: OS_VALUE.expiring,
		labelColor: {
			classColor: 'os-expiring',
			basic: 'green',
			gradient: {
				start: '#388FFE',
				end: '#026FFA'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.EXPIRED',
		value: OS_VALUE.expired,
		labelColor: {
			classColor: 'os-expired',
			basic: 'green',
			gradient: {
				start: '#44C5FA',
				end: '#00B4FD'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.UNKNOWN',
		value: OS_VALUE.unknown,
		labelColor: {
			classColor: 'os-unknown',
			basic: 'green',
			gradient: {
				start: '#B2B2B2',
				end: '#6E6E6E'
			}
		}
	}
]
export const WARRANTY_OPTIONS = [
	{
		label: 'PAGES.DEVICES.STATUSES.UNDEFINED',
		value: WARRANTY_VALUE.undefined,
		labelColor: {
			classColor: 'undefined',
			basic: 'white',
			gradient: {
				start: '#cccccc',
				end: '#a6a6a6'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.SUPPORTED',
		value: WARRANTY_VALUE.supported,
		labelColor: {
			classColor: 'supported',
			basic: 'green',
			gradient: {
				start: '#9bc65f',
				end: '#006537'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.EXPIRING',
		value: WARRANTY_VALUE.expiring,
		labelColor: {
			classColor: 'expiring',
			basic: 'orange',
			gradient: {
				start: '#ffd244',
				end: '#c14639'
			}
		}
	},
	{
		label: 'PAGES.DEVICES.STATUSES.EXPIRED',
		value: WARRANTY_VALUE.expired,
		labelColor: {
			classColor: 'expired',
			basic: 'red',
			gradient: {
				start: '#8d0000',
				end: '#e50909'
			}
		}
	}
]
export const WARRANTY_EXPIRING_DAYS = 90
const ITEMS_PER_PAGE_OPTIONS = [
	{ label: '5', value: 5 },
	{ label: '10', value: 10 },
	{ label: '20', value: 20 },
	{ label: '50', value: 50 }
]
export const pageItemsCreators = (translator, extraOption) => {
	const options = ITEMS_PER_PAGE_OPTIONS.map(item => ({
		...item,
		label: `${item.label} ${translator}`
	}))
	return [...options, extraOption]
}
export const TICKETS_REQUEST_DAY = 90
export const LIST_RECORDS = 10
export const BASE_AUTOTASK_URL =
	'https://webservices4.autotask.net/atservicesrest/v1.0/'
export const PERSIST_KEY = 'root'
//USERS
export const DEFAULT_USER_AMOUNT = 10
export const DEFAULT_SERVICE_REQUESTS_PER_PAGE = 10
export const DEFAULT_COMPANIES_PER_PAGE = 12

// time delay to fetch new list when change items amount of page
export const FETCHING_TIMER = 500

export const USER_FORM_TABS = [
	{ label: 'PAGES.USER_MANAGEMENT.TABS.USER_INFO', value: 'user' },
	{ label: 'PAGES.USER_MANAGEMENT.TABS.COMPANY', value: 'company' },
	{ label: 'PAGES.USER_MANAGEMENT.TABS.COMPANY_LOCATIONS', value: 'locations' }
]
export const CONTACT_REQUESTS_TABS = [
	{ label: 'PAGES.CONTACT_REQUESTS.TABS.LATEST', value: 'latest' },
	{ label: 'PAGES.CONTACT_REQUESTS.TABS.NEW', value: 'new' }
]
export const TICKET_STATUS = {
	inProgress: 'inProgress',
	done: 'done'
}
export const TICKET_STATUS_OPTIONS = [
	{
		text: 'In Progress',
		value: TICKET_STATUS.inProgress
	},
	{
		text: 'Done',
		value: TICKET_STATUS.done
	}
]
export const AULA_ROLES = {
	super_admin: 'super_admin',
	company_admin: 'company_admin',
	aula_orderer: 'aula_orderer',
	aula_pre_orderer: 'aula_pre_orderer',
	aula_viewer: 'aula_viewer'
}
export const AULA_ROLE_OPTIONS = [
	{
		label: 'ROLES.COMPANY_SUPER_ADMIN',
		value: AULA_ROLES.super_admin,
		textValue: AULA_ROLES.super_admin
	},
	{
		label: 'ROLES.COMPANY_ADMIN',
		value: AULA_ROLES.company_admin,
		textValue: AULA_ROLES.company_admin
	},
	{
		label: 'ROLES.ORDERER',
		value: AULA_ROLES.aula_orderer,
		textValue: AULA_ROLES.aula_orderer
	},
	{
		label: 'ROLES.PRE_ORDERER',
		value: AULA_ROLES.aula_pre_orderer,
		textValue: AULA_ROLES.aula_pre_orderer
	},
	{
		label: 'ROLES.VIEWER',
		value: AULA_ROLES.aula_viewer,
		textValue: AULA_ROLES.aula_viewer
	}
]
export const ECOMMERCE_ROLES = {
	orderer: 'ORDER',
	pre_orderer: 'PRE ORDER',
	viewer: 'BROWSING',
	noAccess: 'no-access'
}
export const ECOMMERCE_ROLE_OPTIONS = [
	{
		label: 'ROLES.ORDERER',
		value: ECOMMERCE_ROLES.orderer,
		textValue: ECOMMERCE_ROLES.orderer
	},
	{
		label: 'ROLES.PRE_ORDERER',
		value: ECOMMERCE_ROLES.pre_orderer,
		textValue: ECOMMERCE_ROLES.pre_orderer
	},
	{
		label: 'ROLES.VIEWER',
		value: ECOMMERCE_ROLES.viewer,
		textValue: ECOMMERCE_ROLES.viewer
	},
	{
		label: 'ROLES.NO_ACCESS',
		value: ECOMMERCE_ROLES.noAccess,
		textValue: ECOMMERCE_ROLES.noAccess
	}
]

export const AUTOTASK_ROLES = {
	primary_contact: 'primary_contact',
	billing_contact: 'billing_contact',
	purchasing: 'purchasing',
	technical: 'technical'
}
export const AUTOTASK_ROLE_OPTIONS = [
	{
		label: 'ROLES.PRIMARY_CONTACT',
		value: AUTOTASK_ROLES.primary_contact,
		textValue: AUTOTASK_ROLES.primary_contact
	},
	{
		label: 'ROLES.BILLING_CONTACT',
		value: AUTOTASK_ROLES.billing_contact,
		textValue: AUTOTASK_ROLES.billing_contact
	},
	{
		label: 'ROLES.PURCHASING',
		value: AUTOTASK_ROLES.purchasing,
		textValue: AUTOTASK_ROLES.purchasing
	},
	{
		label: 'ROLES.TECHNICAL',
		value: AUTOTASK_ROLES.technical,
		textValue: AUTOTASK_ROLES.technical
	}
]
// Color code
export const primaryColor = '#717171'
export const status = {
	1: 'customerResponded',
	2: 'new',
	3: 'waitingApproval',
	4: 'dispatched',
	5: 'changeOrder',
	6: 'customerNoteAdded',
	7: 'inProgress',
	8: 'escalate',
	9: 'waitingMaterial',
	10: 'waitingCustomer',
	11: 'waitingVendor',
	12: 'onHold',
	13: 'billed',
	14: 'inActive',
	15: 'readyToBill',
	16: 'complete'
}
export const STATUS_DATA = [
	{
		label: 'STATUSES.NEW',
		value: 1,
		textValue: 'new',
		labelColor: {
			classColor: 'new',
			basic: 'lightsalmon'
		}
	},
	{
		label: 'STATUSES.COMPLETE',
		value: 5,
		textValue: 'complete',
		labelColor: {
			classColor: 'complete',
			basic: 'green'
		}
	},
	{
		label: 'STATUSES.WAITING_CUSTOMER',
		value: 7,
		textValue: 'waitingCustomer',
		labelColor: {
			classColor: 'waitingCustomer',
			basic: 'gray'
		}
	},
	{
		label: 'STATUSES.IN_PROGRESS',
		value: 8,
		textValue: 'inProgress',
		labelColor: {
			classColor: 'inProgress',
			basic: 'slateblue'
		}
	},
	{
		label: 'STATUSES.WAITING_MATERIAL',
		value: 9,
		textValue: 'waitingMaterial',
		labelColor: {
			classColor: 'waitingMaterial',
			basic: 'pink'
		}
	},
	{
		label: 'STATUSES.DISPATCHED',
		value: 10,
		textValue: 'dispatched',
		labelColor: {
			classColor: 'dispatched',
			basic: 'purple'
		}
	},
	{
		label: 'STATUSES.ESCALATE',
		value: 11,
		textValue: 'escalate',
		labelColor: {
			classColor: 'escalate',
			basic: 'yellow'
		}
	},
	{
		label: 'STATUSES.WAITING_VENDOR',
		value: 12,
		textValue: 'waitingVendor',
		labelColor: {
			classColor: 'waitingVendor',
			basic: 'aqua'
		}
	},
	{
		label: 'STATUSES.WAITING_APPROVAL',
		value: 13,
		textValue: 'waitingApproval',
		labelColor: {
			classColor: 'waitingApproval',
			basic: 'orange'
		}
	},
	{
		label: 'STATUSES.BILLED',
		value: 14,
		textValue: 'billed',
		labelColor: {
			classColor: 'billed',
			basic: 'fuchsia'
		}
	},
	{
		label: 'STATUSES.CHANGE_ORDER',
		value: 15,
		textValue: 'changeOrder',
		labelColor: {
			classColor: 'changeOrder',
			basic: 'olive'
		}
	},
	{
		label: 'STATUSES.IN_ACTIVE',
		value: 16,
		textValue: 'inActive',
		labelColor: {
			classColor: 'inActive',
			basic: 'gold'
		}
	},
	{
		label: 'STATUSES.ON_HOLD',
		value: 17,
		textValue: 'onHold',
		labelColor: {
			classColor: 'onHold',
			basic: 'tomato'
		}
	},
	{
		label: 'STATUSES.READY_TO_BILL',
		value: 18,
		textValue: 'readyToBill',
		labelColor: {
			classColor: 'readyToBill',
			basic: 'brown'
		}
	},
	{
		label: 'STATUSES.CUSTOMER_NOTE_ADDED',
		value: 19,
		textValue: 'customerNoteAdded',
		labelColor: {
			classColor: 'customerNoteAdded',
			basic: 'navy'
		}
	},
	{
		label: 'STATUSES.CUSTOMER_RESPONDED',
		value: 20,
		textValue: 'customerResponded',
		labelColor: {
			classColor: 'customerResponded',
			basic: 'red'
		}
	}
]
export const PRIORITY_DATA = [
	{ label: 'PRIORITIES.CRITICAL', value: 1, textValue: 'critical' },
	{ label: 'PRIORITIES.HIGH', value: 2, textValue: 'high' },
	{ label: 'PRIORITIES.MEDIUM', value: 3, textValue: 'medium' },
	{ label: 'PRIORITIES.LOW', value: 4, textValue: 'low' }
]

//notification
export const NOTIFICATION_DURATION = 1500
export const NOTIFICATION_TYPE = {
	success: 'success',
	error: 'error',
	warning: 'warning',
	info: 'info'
}

//sub issue type
export const SUB_ISSUE_TYPES = [
	{
		value: '11',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '16',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '17',
		label: 'SUB_ISSUE_TYPES.PRINTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '19',
		label: 'SUB_ISSUE_TYPES.WORKSTATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '44',
		label: 'SUB_ISSUE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '45',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '46',
		label: 'SUB_ISSUE_TYPES.INTRANET/LAN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '87',
		label: 'SUB_ISSUE_TYPES.POINT_OF_SALE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	},
	{
		value: '104',
		label: 'SUB_ISSUE_TYPES.APPLICATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '105',
		label: 'SUB_ISSUE_TYPES.NEW_SETUP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '108',
		label: 'SUB_ISSUE_TYPES.SPYWARE/MARLWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '109',
		label: 'SUB_ISSUE_TYPES.SYSTEM_PERFORMANCE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '110',
		label: 'SUB_ISSUE_TYPES.VIRUS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '111',
		label: 'SUB_ISSUE_TYPES.DNS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '112',
		label: 'SUB_ISSUE_TYPES.FIREWALL/ROUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '116',
		label: 'SUB_ISSUE_TYPES.WAP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '117',
		label: 'SUB_ISSUE_TYPES.BACKUP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '118',
		label: 'SUB_ISSUE_TYPES.DHCP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '119',
		label: 'SUB_ISSUE_TYPES.DNS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '120',
		label: 'SUB_ISSUE_TYPES.FILE_SYSTEM',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '121',
		label: 'SUB_ISSUE_TYPES.MESSAGING',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '124',
		label: 'SUB_ISSUE_TYPES.PERFORMANCE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '125',
		label: 'SUB_ISSUE_TYPES.SQL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '126',
		label: 'SUB_ISSUE_TYPES.USER_MANAGEMENT',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '127',
		label: 'SUB_ISSUE_TYPES.BILLING',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '6',
		isActive: true,
		isSystem: false
	},
	{
		value: '128',
		label: 'SUB_ISSUE_TYPES.CONSULTING',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '6',
		isActive: true,
		isSystem: false
	},
	{
		value: '129',
		label: 'SUB_ISSUE_TYPES.DEVELOPMENT',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '6',
		isActive: true,
		isSystem: false
	},
	{
		value: '130',
		label: 'SUB_ISSUE_TYPES.SPEC_QUOTE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '6',
		isActive: true,
		isSystem: false
	},
	{
		value: '131',
		label: 'SUB_ISSUE_TYPES.TRAINING',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '6',
		isActive: true,
		isSystem: false
	},
	{
		value: '132',
		label: 'SUB_ISSUE_TYPES.ANTI_VIRUS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '133',
		label: 'SUB_ISSUE_TYPES.BACK_UP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '134',
		label: 'SUB_ISSUE_TYPES.DESKTOP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '135',
		label: 'SUB_ISSUE_TYPES.EMAIL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '136',
		label: 'SUB_ISSUE_TYPES.FIREWALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '137',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '138',
		label: 'SUB_ISSUE_TYPES.INTRANET/LAN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '139',
		label: 'SUB_ISSUE_TYPES.NETWORK',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '140',
		label: 'SUB_ISSUE_TYPES.OTHER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '141',
		label: 'SUB_ISSUE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '142',
		label: 'SUB_ISSUE_TYPES.POINT_OF_SALE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '143',
		label: 'SUB_ISSUE_TYPES.PRINTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '144',
		label: 'SUB_ISSUE_TYPES.ROUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '145',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '146',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '147',
		label: 'SUB_ISSUE_TYPES.VIRUS_REMOVAL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '148',
		label: 'SUB_ISSUE_TYPES.WEBSITE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '149',
		label: 'SUB_ISSUE_TYPES.WIRELESS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '150',
		label: 'SUB_ISSUE_TYPES.WORKSTATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '12',
		isActive: true,
		isSystem: false
	},
	{
		value: '152',
		label: 'SUB_ISSUE_TYPES.HARDWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '153',
		label: 'SUB_ISSUE_TYPES.OPERATING_SYSTEM',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '154',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '10',
		isActive: true,
		isSystem: false
	},
	{
		value: '155',
		label: 'SUB_ISSUE_TYPES.ANTI_VIRUS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '156',
		label: 'SUB_ISSUE_TYPES.BACKUP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '157',
		label: 'SUB_ISSUE_TYPES.EMAIL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '158',
		label: 'SUB_ISSUE_TYPES.FIREWALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '159',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '160',
		label: 'SUB_ISSUE_TYPES.INTRANET/LAN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '161',
		label: 'SUB_ISSUE_TYPES.NETWORK',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '162',
		label: 'SUB_ISSUE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '163',
		label: 'SUB_ISSUE_TYPES.POINT_OF_SALE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '164',
		label: 'SUB_ISSUE_TYPES.ROUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '165',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '166',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '167',
		label: 'SUB_ISSUE_TYPES.WEBSITE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '168',
		label: 'SUB_ISSUE_TYPES.WORKSTATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '13',
		isActive: true,
		isSystem: false
	},
	{
		value: '169',
		label: 'SUB_ISSUE_TYPES.BACKUP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '170',
		label: 'SUB_ISSUE_TYPES.CONNECTIVITY',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '171',
		label: 'SUB_ISSUE_TYPES.DISK_SPACE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '172',
		label: 'SUB_ISSUE_TYPES.FIREWALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '173',
		label: 'SUB_ISSUE_TYPES.HARDWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '174',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '175',
		label: 'SUB_ISSUE_TYPES.INTRANET/LAN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '176',
		label: 'SUB_ISSUE_TYPES.NETWORK',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '177',
		label: 'SUB_ISSUE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '178',
		label: 'SUB_ISSUE_TYPES.POINT_OF_SALE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '179',
		label: 'SUB_ISSUE_TYPES.PRINTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '180',
		label: 'SUB_ISSUE_TYPES.ROUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '181',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '182',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '183',
		label: 'SUB_ISSUE_TYPES.WORKSTATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '14',
		isActive: true,
		isSystem: false
	},
	{
		value: '184',
		label: 'SUB_ISSUE_TYPES.CABLING',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '185',
		label: 'SUB_ISSUE_TYPES.CONFIGURATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '186',
		label: 'SUB_ISSUE_TYPES.CONNECTIVITY',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '187',
		label: 'SUB_ISSUE_TYPES.HUB',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '188',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '189',
		label: 'SUB_ISSUE_TYPES.ISP',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '190',
		label: 'SUB_ISSUE_TYPES.LOGON_FAILURE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '191',
		label: 'SUB_ISSUE_TYPES.NETWORK_PRINTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '192',
		label: 'SUB_ISSUE_TYPES.PERFORMANCE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '193',
		label: 'SUB_ISSUE_TYPES.REMOTE_ACCESS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '194',
		label: 'SUB_ISSUE_TYPES.SECURITY',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '195',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '196',
		label: 'SUB_ISSUE_TYPES.SETTINGS_CHANGE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '198',
		label: 'SUB_ISSUE_TYPES.VPN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '199',
		label: 'SUB_ISSUE_TYPES.SWITCH',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '11',
		isActive: true,
		isSystem: false
	},
	{
		value: '200',
		label: 'SUB_ISSUE_TYPES.EMAIL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '201',
		label: 'SUB_ISSUE_TYPES.FIREWALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '202',
		label: 'SUB_ISSUE_TYPES.HARDWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '203',
		label: 'SUB_ISSUE_TYPES.INTERNET',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '204',
		label: 'SUB_ISSUE_TYPES.INTRANET/LAN',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '205',
		label: 'SUB_ISSUE_TYPES.NETWORK',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '206',
		label: 'SUB_ISSUE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '207',
		label: 'SUB_ISSUE_TYPES.POINT_OF_SALE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '208',
		label: 'SUB_ISSUE_TYPES.PRINTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '209',
		label: 'SUB_ISSUE_TYPES.ROUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '210',
		label: 'SUB_ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '211',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '212',
		label: 'SUB_ISSUE_TYPES.WORKSTATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '15',
		isActive: true,
		isSystem: false
	},
	{
		value: '213',
		label: 'SUB_ISSUE_TYPES.ACTIVE_DIRECTORY',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '214',
		label: 'SUB_ISSUE_TYPES.ANTI_VIRUS',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '215',
		label: 'SUB_ISSUE_TYPES.APPLICATION',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '216',
		label: 'SUB_ISSUE_TYPES.EXCHANGE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '217',
		label: 'SUB_ISSUE_TYPES.HARDWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '218',
		label: 'SUB_ISSUE_TYPES.SOFTWARE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '7',
		isActive: true,
		isSystem: false
	},
	{
		value: '219',
		label: 'SUB_ISSUE_TYPES.FIREWALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '4',
		isActive: true,
		isSystem: false
	}
]

//Queue type
export const QUEUE_OPTIONS = [
	{
		value: '5',
		label: 'QUEUE_TYPES.CLIENT_PORTAL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '6',
		label: 'QUEUE_TYPES.POST_SALE',
		isDefaultValue: false,
		sortOrder: 1,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '8',
		label: 'QUEUE_TYPES.MONITORING_ALERT',
		isDefaultValue: false,
		sortOrder: 2,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '29682833',
		label: 'QUEUE_TYPES.SERVICE_DESK',
		isDefaultValue: false,
		sortOrder: 3,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29682969',
		label: 'QUEUE_TYPES.SERVICE_DESK_2',
		isDefaultValue: false,
		sortOrder: 4,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683354',
		label: 'QUEUE_TYPES.RECURRING_TICKETS',
		isDefaultValue: false,
		sortOrder: 5,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683378',
		label: 'QUEUE_TYPES.ADMINISTRATION',
		isDefaultValue: false,
		sortOrder: 6,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683515',
		label: 'QUEUE_TYPES.SALES',
		isDefaultValue: false,
		sortOrder: 7,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683516',
		label: 'QUEUE_TYPES.PROJECTS',
		isDefaultValue: false,
		sortOrder: 8,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683517',
		label: 'QUEUE_TYPES.HIGH_ALERT',
		isDefaultValue: false,
		sortOrder: 9,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683518',
		label: 'QUEUE_TYPES.DEVELOPMENT',
		isDefaultValue: false,
		sortOrder: 10,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683519',
		label: 'QUEUE_TYPES.INTERNAL',
		isDefaultValue: false,
		sortOrder: 11,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683520',
		label: 'QUEUE_TYPES.DATA_ENTER',
		isDefaultValue: false,
		sortOrder: 12,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683521',
		label: 'QUEUE_TYPES.BACKUP_REPORTS',
		isDefaultValue: false,
		sortOrder: 13,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683522',
		label: 'QUEUE_TYPES.WEEKLY_CHECKLISTS',
		isDefaultValue: false,
		sortOrder: 14,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '29683523',
		label: 'QUEUE_TYPES.BILLING',
		isDefaultValue: false,
		sortOrder: 15,
		parentValue: '',
		isActive: true,
		isSystem: false
	}
]

// source types
export const SOURCE_TYPES = [
	{
		value: '-2',
		label: 'SOURCE_TYPES.INSOURCED',
		isDefaultValue: false,
		sortOrder: 1,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '-1',
		label: 'SOURCE_TYPES.CLIENT_PORTAL',
		isDefaultValue: false,
		sortOrder: 1,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '1',
		label: 'SOURCE_TYPES.VOICE_MAIL',
		isDefaultValue: false,
		sortOrder: 55,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '2',
		label: 'SOURCE_TYPES.PHONE',
		isDefaultValue: false,
		sortOrder: 1,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '4',
		label: 'SOURCE_TYPES.EMAIL',
		isDefaultValue: false,
		sortOrder: 30,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '5',
		label: 'SOURCE_TYPES.WEB_PORTAL',
		isDefaultValue: false,
		sortOrder: 50,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '6',
		label: 'SOURCE_TYPES.IN_PERSON_ONSITE',
		isDefaultValue: false,
		sortOrder: 20,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '8',
		label: 'SOURCE_TYPES.MONITORING_ALERT',
		isDefaultValue: false,
		sortOrder: 40,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '11',
		label: 'SOURCE_TYPES.VERBAL',
		isDefaultValue: false,
		sortOrder: 60,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '12',
		label: 'SOURCE_TYPES.WEBSITE',
		isDefaultValue: false,
		sortOrder: 65,
		parentValue: '',
		isActive: true,
		isSystem: true
	}
]

export const ISSUE_TYPES = [
	{
		value: '4',
		label: 'ISSUE_TYPES.UPGRADE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '6',
		label: 'ISSUE_TYPES.SERVICE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '7',
		label: 'ISSUE_TYPES.SERVER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '10',
		label: 'ISSUE_TYPES.COMPUTER',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '11',
		label: 'ISSUE_TYPES.NETWORK',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '12',
		label: 'ISSUE_TYPES.BREAK/FIX',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '13',
		label: 'ISSUE_TYPES.MAINTENANCE',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '14',
		label: 'ISSUE_TYPES.MONITORING_ALERT',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	},
	{
		value: '15',
		label: 'ISSUE_TYPES.NEW_INSTALL',
		isDefaultValue: false,
		sortOrder: 0,
		parentValue: '',
		isActive: true,
		isSystem: false
	}
]
export const WORK_TYPES = [
	{
		afterHoursWorkType: null,
		billingCodeType: 0,
		department: null,
		description: null,
		externalNumber: null,
		generalLedgerAccount: null,
		isActive: true,
		isExcludedFromNewContracts: false,
		markupRate: null,
		name: 'Paikan päällä annettava tuki',
		taxCategoryID: 1,
		unitCost: 0,
		unitPrice: 0,
		useType: 1,
		companyId: 9,
		autotaskId: 29682800
	},
	{
		afterHoursWorkType: null,
		billingCodeType: 0,
		department: null,
		description: null,
		externalNumber: null,
		generalLedgerAccount: null,
		isActive: true,
		isExcludedFromNewContracts: false,
		markupRate: null,
		name: 'Etätuki',
		taxCategoryID: 1,
		unitCost: 0,
		unitPrice: 0,
		useType: 1,
		companyId: 9,
		autotaskId: 29682801
	}
	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 0,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'Emergency/After Hours Support',
	// 	taxCategoryID: 1,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29682802
	// },
	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 0,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'Maintenance',
	// 	taxCategoryID: 1,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29682804
	// },
	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 2,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'General Administration',
	// 	taxCategoryID: null,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29682808
	// },

	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 2,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'Sales',
	// 	taxCategoryID: null,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29682860
	// },
	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 2,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'Non Billable Support',
	// 	taxCategoryID: null,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29682861
	// },
	// {
	// 	afterHoursWorkType: null,
	// 	billingCodeType: 0,
	// 	department: null,
	// 	description: null,
	// 	externalNumber: null,
	// 	generalLedgerAccount: null,
	// 	isActive: true,
	// 	isExcludedFromNewContracts: false,
	// 	markupRate: null,
	// 	name: 'Travel',
	// 	taxCategoryID: null,
	// 	unitCost: 0,
	// 	unitPrice: 0,
	// 	useType: 1,
	// 	companyId: 9,
	// 	autotaskId: 29683328
	// }
]
export const MESSAGE_CODE = {
	1000: 'Invalid access token',
	1001: 'Invalid refresh token',
	1004: 'Invalid confirm token',
	1005: 'User already verified',
	1006: 'User not verified',
	1007: 'Email already used',
	1008: 'Invalid credential',
	1009: 'Unauthorized to modify',
	1010: 'Verification failed',
	1011: 'Invalid authentication',
	1012: 'Expired access token',
	1013: 'Expired refresh token',
	1014: 'Requires admin access',
	1015: 'Invalid password reset token',
	1016: 'Expired password reset token',
	1017: 'Invalid cron access',
	1018: 'Missing request body',
	1019: 'Invalid request',
	1020: 'Role not authorized',
	1021: 'Entity not found',
	1022: 'Missing query params',
	1023: 'Invalid enum',
	1024: 'Invalid boolean',
	1025: 'Missing URL param',
	1026: 'Missing body param',
	1027: 'Invalid user',
	1028: 'Invalid company',
	1029: 'Invalid ticket',
	1030: 'Failed to create',
	1031: 'Failed to edit',
	1032: 'Invalid ticket attachment',
	1033: 'Invalid csv file',
	1034: 'Invalid role',
	1035: 'User not found',
	1036: 'Company not found',
	1037: 'Invalid Ncentral company id',
	1038: 'Not an Aula company',
	1039: 'Invalid user details',
	1040: 'Err: inactivate company',
	1041: 'Err: inactivate user',
	1042: 'Insufficient user data',
	1043: 'Invalid time entry',
	1044: 'Forbidden to role',
	1045: 'Missing required params',
	1046: 'Forbidden company',
	1047: 'Unexpected role',
	9000: 'Interal middleware error',
	9001: 'Failed to save refresh token',
	9002: 'Missing required middleware',
	9003: 'Failed to validate user creation',
	9004: 'Failed to validate user modification'
}
export const PERMISSION_FIELDS = {
	dashboard_webshop: 'dashboard_webshop',
	user_list_function: 'user_list_function',
	user_list_import: 'user_list_import',
	user_list_export: 'user_list_export',
	edit_user: 'edit_user',
	new_request: 'new_request',
	edit_request: 'edit_request',
	new_request_message: 'new_request_message'
}
export const PERMISSIONS = {
	[AULA_ROLES.aula_orderer]: {
		[PERMISSION_FIELDS.dashboard_webshop]: true,
		[PERMISSION_FIELDS.user_list_function]: true,
		[PERMISSION_FIELDS.user_list_import]: true,
		[PERMISSION_FIELDS.user_list_export]: true,
		[PERMISSION_FIELDS.edit_user]: true,
		[PERMISSION_FIELDS.new_request]: true,
		[PERMISSION_FIELDS.edit_request]: true,
		[PERMISSION_FIELDS.new_request_message]: false
	},
	[AULA_ROLES.aula_pre_orderer]: {
		[PERMISSION_FIELDS.dashboard_webshop]: true,
		[PERMISSION_FIELDS.user_list_function]: true,
		[PERMISSION_FIELDS.user_list_import]: true,
		[PERMISSION_FIELDS.user_list_export]: true,
		[PERMISSION_FIELDS.edit_user]: true,
		[PERMISSION_FIELDS.new_request]: true,
		[PERMISSION_FIELDS.edit_request]: true,
		[PERMISSION_FIELDS.new_request_message]: false
	},
	[AULA_ROLES.aula_viewer]: {
		[PERMISSION_FIELDS.dashboard_webshop]: false,
		[PERMISSION_FIELDS.user_list_function]: false,
		[PERMISSION_FIELDS.user_list_import]: false,
		[PERMISSION_FIELDS.user_list_export]: false,
		[PERMISSION_FIELDS.edit_user]: false,
		[PERMISSION_FIELDS.new_request]: false,
		[PERMISSION_FIELDS.edit_request]: false,
		[PERMISSION_FIELDS.new_request_message]: false
	}
}
export const TYPE_GROUP_OPTIONS = [
	{
		label: 'Servers',
		value: 1
	},
	{
		label: 'Workstations',
		value: 2
	},
	{
		label: 'VMs',
		value: 3
	},
	{
		label: 'Switch/Router',
		value: 4
	},
	{
		label: 'Others',
		value: 5
	}
]
export const FILTER_ORDER_OPTIONS = [
	{
		label: 'FILTERS.ASCENDING',
		value: 1
	},
	{
		label: 'FILTERS.DESCENDING',
		value: -1
	}
]
const default_user_preferences = {
	user: [
		{ value: 'name', select: true, isSorted: false },
		{ value: 'companyLocationName', select: true, isSorted: false },
		{ value: 'email', select: true, isSorted: true },
		{ value: 'mobilePhone', select: true, isSorted: false },
		{
			value: 'aulaRoleLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: -1, select: false },
				{ value: 'super_admin', select: true },
				{ value: 'company_admin', select: false },
				{ value: 'aula_orderer', select: false },
				{ value: 'aula_pre_orderer', select: false },
				{ value: 'aula_viewer', select: false }
			]
		},
		{
			value: 'ecommerceRoleLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: -1, select: false },
				{ value: 'ORDER', select: true },
				{ value: 'PRE ORDER', select: false },
				{ value: 'BROWSING', select: false }
			]
		},
		{
			value: 'autotaskRoleLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: -1, select: false },
				{ value: 'primary_contact', select: true },
				{ value: 'billing_contact', select: false },
				{ value: 'purchasing', select: false },
				{ value: 'technical', select: false }
			]
		}
	],
	tickets: [
		{ value: 'ticketNumber', select: true, isSorted: false },
		{ value: 'title', select: true, isSorted: true },
		{ value: 'contactName', select: true },
		{
			value: 'priorityLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: 1, select: true },
				{ value: 2, select: false },
				{ value: 3, select: false },
				{ value: 4, select: false }
			]
		},
		{
			value: 'statusLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: 1, select: true },
				{ value: 5, select: false },
				{ value: 7, select: false },
				{ value: 8, select: false },
				{ value: 9, select: false },
				{ value: 10, select: false },
				{ value: 11, select: false },
				{ value: 12, select: false },
				{ value: 13, select: false },
				{ value: 14, select: false },
				{ value: 15, select: false },
				{ value: 16, select: false },
				{ value: 17, select: false },
				{ value: 18, select: false },
				{ value: 19, select: false },
				{ value: 20, select: false }
			]
		}
	],
	devices: [
		{ value: 'longname', select: true, isSorted: true },
		{ value: 'lastloggedinuser', select: true, isSorted: false },
		{
			value: 'deviceclass',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: 1, select: true },
				{ value: 2, select: false },
				{ value: 3, select: false },
				{ value: 4, select: false },
				{ value: 5, select: false }
			]
		},
		{ value: 'serialnumber', select: true, isSorted: false },
		{ value: 'model', select: true, isSorted: false },
		{ value: 'sitename', select: true, isSorted: false },
		{
			value: 'totalphysicalmemory',
			select: false,
			isSorted: false,
			data: [
				{ value: '<=8', select: true, label: '<=8' },
				{ value: '8', select: false, label: '>8' }
			]
		},
		{ value: 'physicaldrive', select: false, isSorted: false },
		{ value: 'processor', select: false, isSorted: false },
		{ value: 'systemtype', select: true, isSorted: false },
		{ value: 'age', select: true, isSorted: false },
		{
			value: 'expireLabel',
			select: true,
			isSorted: false,
			data: [
				{ value: 0, select: false },
				{ value: 'supported', select: true },
				{ value: 'expiring', select: false },
				{ value: 'expired', select: false },
				{ value: 'undefined', select: false }
			]
		}
	]
}

export const DEVICE_FIELDS_EXPORT_CSV = [
	{
		label: 'Device name',
		fieldValue: 'longname',
		filterValue: 'longname'
	},
	{
		label: 'User',
		fieldValue: 'lastloggedinuser',
		filterValue: 'lastloggedinuser'
	},
	{
		label: 'Device class',
		fieldValue: 'deviceclass',
		filterValue: 'deviceclass'
	},
	{
		label: 'Serial Number',
		fieldValue: 'serialnumber',
		filterValue: 'serialnumber'
	},
	{
		label: 'Model',
		fieldValue: 'model',
		filterValue: 'model'
	},
	{
		label: 'Location',
		fieldValue: 'sitename',
		filterValue: 'sitename'
	},
	{
		label: 'Processor',
		fieldValue: 'processorname',
		filterValue: 'processorname'
	},
	{
		label: 'RAM (Gb)',
		fieldValue: 'totalphysicalmemory',
		filterValue: 'totalphysicalmemory'
	},
	{
		label: 'Hard drive (Gb)',
		fieldValue: 'physicaldrive',
		filterValue: 'physicaldrive'
	},
	{
		label: 'System',
		fieldValue: 'reportedos',
		filterValue: 'reportedos'
	},
	{
		label: 'Version',
		fieldValue: 'version',
		filterValue: 'version'
	},
	{
		label: 'ExpireLabel',
		fieldValue: 'warrantyexpirydate',
		filterValue: 'warrantyexpirydate'
	}
]
export const USER_FIELDS_EXPORT_CSV = [
	{
		label: 'Company ID',
		fieldValue: 'companyAutotaskId',
		filterValue: 'companyAutotaskId'
	},
	{ label: '[required] Company Name', fieldValue: '', filterValue: '' },
	{ label: 'Contact ID [updates only]', fieldValue: '', filterValue: '' },
	{ label: 'External ID', fieldValue: '', filterValue: '' },
	{ label: 'Prefix', fieldValue: '', filterValue: '' },
	{
		label: '[required] First Name',
		fieldValue: 'firstName',
		filterValue: 'name'
	},
	{ label: 'Middle Name', fieldValue: '', filterValue: '' },
	{
		label: '[required] Last Name',
		fieldValue: 'lastName',
		filterValue: 'name'
	},
	{ label: 'Suffix', fieldValue: '', filterValue: '' },
	{ label: 'Title', fieldValue: '', filterValue: '' },
	{
		label: '[required] Email Address',
		fieldValue: 'email',
		filterValue: 'email'
	},
	{ label: 'Email Address 2', fieldValue: '', filterValue: '' },
	{ label: 'Email Address 3', fieldValue: '', filterValue: '' },
	{ label: 'Location', fieldValue: '', filterValue: '' },
	{ label: 'Address 1', fieldValue: '', filterValue: '' },
	{ label: 'Address 2', fieldValue: '', filterValue: '' },
	{ label: 'City', fieldValue: '', filterValue: '' },
	{ label: 'County', fieldValue: '', filterValue: '' },
	{ label: 'Post Code', fieldValue: '', filterValue: '' },
	{ label: 'Country', fieldValue: '', filterValue: '' },
	{
		label: 'Additional Address Information',
		fieldValue: '',
		filterValue: ''
	},
	{ label: 'Phone', fieldValue: '', filterValue: '' },
	{ label: 'Extension', fieldValue: '', filterValue: '' },
	{ label: 'Alternate Phone', fieldValue: '', filterValue: '' },
	{
		label: 'Mobile Phone',
		fieldValue: 'mobilePhone',
		filterValue: 'mobilePhone'
	},
	{ label: 'Fax', fieldValue: '', filterValue: '' },
	{ label: 'Facebook URL', fieldValue: '', filterValue: '' },
	{ label: 'Twitter URL', fieldValue: '', filterValue: '' },
	{ label: 'Client Portal User Name', fieldValue: '', filterValue: '' },
	{
		label: 'Client Portal Security Level',
		fieldValue: '',
		filterValue: ''
	},
	{ label: 'Contact Group Name', fieldValue: '', filterValue: '' },
	{ label: 'New Email Address', fieldValue: '', filterValue: '' },
	{ label: 'Active/Inactive', fieldValue: '', filterValue: '' },
	{ label: 'Primary Contact', fieldValue: '', filterValue: '' },
	{ label: 'Billing Product', fieldValue: '', filterValue: '' },
	{
		label: 'Billing Product Effective Date',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Billing Product Expiration Date',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Accepts Task/Ticket Email (yes or no)',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Contact UDF:29682898 Alternate Email',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Contact UDF:29682860 Customer Contact',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Contact UDF:29682901 Kustannuspaikka',
		fieldValue: '',
		filterValue: ''
	},
	{
		label: 'Aula role',
		fieldValue: 'aulaRole',
		filterValue: 'aulaRoleLabel'
	},
	{
		label: 'Ecommerce role',
		fieldValue: 'ecommerceRole',
		filterValue: 'ecommerceRoleLabel'
	},
	{
		label: 'Autotask role',
		fieldValue: 'autotaskRole',
		filterValue: 'autotaskRoleLabel'
	}
]
export const COMPANY_TYPES = [
	{
		value: '1',
		label: 'Customer',
		isDefaultValue: false,
		sortOrder: 1,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '2',
		label: 'Lead',
		isDefaultValue: false,
		sortOrder: 2,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '3',
		label: 'Prospect',
		isDefaultValue: false,
		sortOrder: 3,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '4',
		label: 'Dead',
		isDefaultValue: false,
		sortOrder: 4,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '6',
		label: 'Cancellation',
		isDefaultValue: false,
		sortOrder: 6,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '7',
		label: 'Vendor',
		isDefaultValue: false,
		sortOrder: 7,
		parentValue: '',
		isActive: true,
		isSystem: true
	},
	{
		value: '8',
		label: 'Partner',
		isDefaultValue: false,
		sortOrder: 8,
		parentValue: '',
		isActive: true,
		isSystem: true
	}
]
export const ORDER_STATUS = {
	confirmed: 2,
	in_delivery: 3,
	delivered: 4,
	invoiced: 5
}
export const ORDER_STATUS_OPTIONS = [
	{
		label: 'ORDER_STATUS.CONFIRMED',
		value: ORDER_STATUS.confirmed,
		valueText: 'confirmed'
	},
	{
		label: 'ORDER_STATUS.IN_DELIVERY',
		value: ORDER_STATUS.in_delivery,
		valueText: 'in_delivery'
	},
	{
		label: 'ORDER_STATUS.DELIVERED',
		value: ORDER_STATUS.delivered,
		valueText: 'delivered'
	},
	{
		label: 'ORDER_STATUS.INVOICED',
		value: ORDER_STATUS.invoiced,
		valueText: 'invoiced'
	}
]
